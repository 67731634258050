import React from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import Cardloading from './Cardloading'

export default function Loading() {
  return (
<>
<Cardloading/>
<Cardloading/>
<Cardloading/>
</>
  )
}
